import { Modal } from 'bootstrap'; // eslint-disable-line

export default class Navigation {
  constructor(el) {
    this.elHeader = el;
    this.elHamburgerBtn = this.elHeader.querySelector('.js-header-hamburger-btn');
    this.elHamburgerBtnIcon = this.elHamburgerBtn.querySelector('.js-header-hamburger-icon');
    this.elNav = this.elHeader.querySelector('.js-nav');

    this.initEvents();
  }

  initEvents() {
    this.elHamburgerBtn.addEventListener('click', () => {
      this.elNav.classList.toggle('js-open');
      this.elHamburgerBtnIcon.classList.toggle('is-active');
    });
  }

  static init(selector) {
    [...document.querySelectorAll(selector)].forEach(el => new this(el));
  }
}
