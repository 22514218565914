import {gsap, Power3} from "gsap";
import { SplitText } from "gsap/SplitText";
import SwipeListener from 'swipe-listener';

gsap.registerPlugin(SplitText);

export default class Story {
  constructor(el) {
    this.el = el;

    // Story background layber
    this.elStoryBg = this.el.querySelector('.js-story-bg');

    // Screen 1 Elements.
    this.elScreen1Wrapper = this.el.querySelector('.js-screen1-wrapper');
    this.elScreen1Content = this.el.querySelector('.js-screen1-content');
    this.elScreen1Img = this.el.querySelector('.js-screen1-img');
    this.elScreen1Txt = this.el.querySelector('.js-screen1-txt');

    // Screen 2 Elements.
    this.elScreen2Wrapper = this.el.querySelector('.js-screen2-wrapper');
    this.elScreen2Img = this.el.querySelector('.js-screen2-img');
    this.elScreen2Txt = this.el.querySelector('.js-screen2-txt');

    // Screen 3 Elements.
    this.elScreen3Wrapper = this.el.querySelector('.js-screen3-wrapper');
    this.elScreen3Txt = this.el.querySelector('.js-screen3-txt');
    this.elScreen3MayanTemple = this.el.querySelector('.js-screen3-mayan-temple');
    this.elScreen3Pyramids = this.el.querySelector('.js-screen3-pyramids');
    this.elScreen3ChineseTemple = this.el.querySelector('.js-screen3-chinese-temple');
    this.elScreen3Colloseum = this.el.querySelector('.js-screen3-colloseum');
    this.elScreen3LadyLiberty = this.el.querySelector('.js-screen3-lady-liberty');
    this.elScreen3TxtRise = this.el.querySelector('.js-screen3-txt-rise');
    this.elScreen3TxtFall = this.el.querySelector('.js-screen3-txt-fall');

    // Screen 4 Elements.
    this.elScreen4Wrapper = this.el.querySelector('.js-screen4-wrapper');
    this.elScreen4Hand = this.el.querySelector('.js-screen4-god-hand');
    this.elScreen4Lightning = this.el.querySelector('.js-screen4-god-lightning');
    this.elScreen4Bg = this.el.querySelector('.js-screen4-bg');

    // Screen 5 Elements.
    this.elScreen5Wrapper = this.el.querySelector('.js-screen5-wrapper');
    this.elScreen5Txt = this.el.querySelector('.js-screen5-txt');
    this.elScreen5Btn = this.el.querySelector('.js-screen5-btn');

    // Scroll down icon.
    this.elScrollDown = this.el.querySelector('.js-scroll-down');

    // Replay story icon.
    this.elReplay = this.el.querySelector('.js-replay');

    // Get all modals (to pause the story when a modal is open).
    this.modals = document.querySelectorAll('.modal');

    // Get cookiemessage
    this.elCookieMessage = document.querySelector('.js-cookie-message');

    // ScrollDetect variable.
    this.screen = 1;
    this.isAnimating = false;

    // Color variables.
    this.bgLight = '#f5f3f3';
    this.bgDark = '#f9f0e8';
    this.bgSuperDark = '#bab1aa';

    // Splittext objects
    this.splitScreen1 = new SplitText(this.elScreen1Txt, {type: 'words'});
    this.splitScreen2 = new SplitText(this.elScreen2Txt, {type: 'words'});
    this.splitScreen3 = new SplitText(this.elScreen3Txt, {type: 'words', wordsClass: 'word++'});
    this.splitScreen5 = new SplitText(this.elScreen5Txt, {type: 'words'});

    /*
    window.addEventListener('scroll', () => {
      // Get the new Value
      const newValue = window.pageYOffset;

      //Subtract the two and conclude
      if(this.oldValue - newValue < 0){
        if (!this.isAnimating) {
          this.initScreen2();
        }
      } else if(this.oldValue - newValue > 0){
      }

      // Update the old value
      this.oldValue = newValue;
    });
     */

    this.initEvents();
    this.initScreen1();
  }

  initEvents() {
    // Desktop.
    window.addEventListener('wheel', (event) => {
      let modalIsOpen = false;

      [...this.modals].forEach((modal) => {
        if (modal.classList.contains('show')) {
          modalIsOpen = true;
        }
      });

      if (this.elCookieMessage.classList.contains('js-visible')) {
        modalIsOpen = true;
      }

      if (!modalIsOpen) {
        // Scrolling up.
        if (event.deltaY < 0) {

          // Scrolling down.
        } else if (event.deltaY > 0) {
          if (!this.isAnimating) {
            this.animateScreenDown();
          }
        }
      }
    }, {passive: true});

    // Mobile.
    const listener = SwipeListener(document);
    document.addEventListener('swipe', (e) => {
      const directions = e.detail.directions;

      if (directions.top) {
        if (!this.isAnimating) {
          this.animateScreenDown();
        }
      }

      if (directions.bottom) {
        if (!this.isAnimating) {
          this.animateScreenDown();
        }
      }
    }, {passive: true});
  }

  // Animate screen
  animateScreenDown() {
    this.screen++;

    switch(this.screen) {
      case 2:
        this.initScreen2();
        break;
      case 3:
        this.initScreen3();
        break;
      case 4:
        this.initScreen4();
        break;
      case 5:
        this.initScreen5();
        break;
      default:
        return;
    }
  }

  // Black-sun screen, sunrise
  initScreen1() {
    this.isAnimating = true;
    gsap.to(this.elScreen1Wrapper, {top: '0', duration: 1, ease: Power3.easeOut});

    // Animate in screen 1 words.
    this.animateWordsIn(this.splitScreen1.words, 0.5);

    // Reveal scroll icon.
    this.revealScrollIcon(1.2);
  }

  initScreen2() {
    this.isAnimating = true;

    // Scroll icon disappears.
    this.hideScrollIcon();

    // Remove screen1 words.
    this.animateWordsOut(this.splitScreen1.words, 0.5);

    // ------ Delay: 1s -------- //

    // Sun moves to the right.
    gsap.to(this.elScreen1Content, {
      top: '10%',
      right: '5%',
      scale: 0.5,
      duration: 1,
      ease: Power3.easeOut,
      delay: 1
    });

    // Background scene changes color.
    gsap.to(this.elStoryBg, {
      backgroundColor: this.bgLight,
      duration: 1,
      ease: Power3.easeOut,
      delay: 1
    });

    // Make Screen 2 Visible
    this.makeScreenVisible(this.elScreen2Wrapper);

    // ------ End Delay: 1s -------- //


    gsap.to(this.elScreen2Img, {
      top: '40%',
      duration: 1,
      ease: Power3.easeOut,
      delay: 1.5
    });

    // Animate in screen 2 words.
    this.animateWordsIn(this.splitScreen2.words, 2);

    // Reveal scroll icon.
    this.revealScrollIcon(3.5);
  }

  initScreen3() {
    this.isAnimating = true;

    // Scroll icon disappears.
    this.hideScrollIcon();

    // Remove screen 2 words.
    this.animateWordsOut(this.splitScreen2.words, 0.5);

    // Hide worldglobe
    gsap.to(this.elScreen2Img, {
      scale: 0,
      opacity: 0,
      duration: 1,
      ease: Power3.easeOut,
      delay: 1
    });

    // Change Background color.
    gsap.to(this.elStoryBg, {
      backgroundColor: this.bgDark,
      duration: 1,
      ease: Power3.easeOut,
      delay: 1
    });

    // Make Screen 4 Visible
    this.makeScreenVisible(this.elScreen3Wrapper);

    // Animate in screen 3 words.
    this.animateWordsIn(this.splitScreen3.words, 1.5);

    // Animate in Mayan temple
    this.animateCivilizationsIn(this.elScreen3MayanTemple, 2.6);

    // Animate in Pyramids
    this.animateCivilizationsIn(this.elScreen3Pyramids, 2.8);

    // Animate in Chinese Temple
    this.animateCivilizationsIn(this.elScreen3ChineseTemple, 3);

    // Animate in Colloseum
    this.animateCivilizationsIn(this.elScreen3Colloseum, 3.2);

    // Animate in Lady Liberty
    this.animateCivilizationsIn(this.elScreen3LadyLiberty, 3.4);

    // Reveal scroll icon.
    this.revealScrollIcon(3.5);

  }

  initScreen4() {
    this.isAnimating = true;

    // Scroll icon disappears.
    this.hideScrollIcon();

    gsap.to(this.elScreen3TxtRise, {y: -20, opacity: 0, duration: 1, ease: Power3.easeOut});
    gsap.to(this.elScreen3TxtFall, {y: 0, opacity: 1, duration: 1, delay: 0.5, ease: Power3.easeOut});

    // Make Screen 4 Visible
    this.makeScreenVisible(this.elScreen4Wrapper);

    // Decend gods hand
    gsap.to(this.elScreen4Hand, {y: 0, opacity: 1, duration: 2, delay: 1.5, ease: Power3.easeOut});

    // Add Lightning BG
    gsap.to(this.elScreen4Lightning, {opacity: 0.8, duration: 0, delay: 3.5, ease: Power3.easeOut});

    // Darken background.
    gsap.to(this.elStoryBg, {
      backgroundColor: this.bgSuperDark,
      duration: 1,
      ease: Power3.easeOut,
      delay: 1
    });

    gsap.to(this.elScreen4Bg, {
      opacity: 1,
      duration: 0.2,
      delay: 3.5,
      ease: Power3.easeOut,
      onComplete: () => {
        // Remove god elements
        gsap.to(this.elScreen4Hand, {y: -100, opacity: 0, duration: 2, delay: 0.5, ease: Power3.easeOut});
        gsap.to(this.elScreen4Lightning, {opacity: 0, duration: 1, ease: Power3.easeOut});
        gsap.to(this.elScreen4Bg, {opacity: 0, duration: 3, ease: Power3.easeOut});

        // Animate out civs
        this.animateCivilizationsOut(this.elScreen3MayanTemple);
        this.animateCivilizationsOut(this.elScreen3Pyramids);
        this.animateCivilizationsOut(this.elScreen3Colloseum);
        this.animateCivilizationsOut(this.elScreen3LadyLiberty);
        this.animateCivilizationsOut(this.elScreen3ChineseTemple);

        // Remove screen 4 words.
        //this.animateWordsOut(this.splitScreen3.words, 0.5);

        // Lighten bg
        gsap.to(this.elStoryBg, {
          backgroundColor: this.bgDark,
          duration: 1,
          ease: Power3.easeOut,
          delay: 0.5,
          onComplete: () => {
            this.revealScrollIcon();
          }
        });
      }
    });
  }

  initScreen5() {
    this.isAnimating = true;

    // Scroll icon disappears.
    this.hideScrollIcon();

    // Remove screen 3 words.
    this.animateWordsOut(this.splitScreen3.words, 0.5);

    // Make Screen 2 Visible
    this.makeScreenVisible(this.elScreen5Wrapper);

    // Animate in screen 3 words.
    this.animateWordsIn(this.splitScreen5.words, 1.5);

    gsap.to(this.elScreen5Btn, {
      y: 0,
      opacity: 1,
      duration: 1,
      ease: Power3.easeOut,
      delay: 3
    });

    gsap.to(this.elReplay, {
      scale: 1,
      opacity: 1,
      duration: 1,
      ease: Power3.easeOut,
      delay: 4
    });
  }

  makeScreenVisible(screen) {
    gsap.to(screen, {
      opacity: 1,
      duration: 1,
      ease: Power3.easeOut,
      delay: 1
    });
  }

  hideScrollIcon() {
    gsap.to(this.elScrollDown, {y: -20, opacity: 0, duration: 1, ease: Power3.easeOut});
  }

  revealScrollIcon(delay) {
    gsap.to(this.elScrollDown, {
      y: 0,
      opacity: 1,
      duration: 1,
      ease: Power3.easeOut,
      delay: delay,
      onComplete: () => {
        this.isAnimating = false;
      }
    });
  }

  animateCivilizationsIn(element, delay) {
    gsap.to(element, {
      opacity: 1,
      scale: 1,
      ease: Power3.easeOut,
      delay: delay
    });
  }

  animateCivilizationsOut(element) {
    gsap.to(element, {
      opacity: 0,
      scale: 0,
      duration: 2,
      ease: Power3.easeOut,
      delay: 0
    });
  }

  animateWordsIn(words, delay) {
    gsap.from(words, {
      opacity: 0,
      y: 50,
      ease: Power3.easeOut,
      delay: delay,
      stagger: {
        each: 0.07
      }
    });
  }

  animateWordsOut(words, delay) {
    gsap.to(words, {
      opacity: 0,
      y: -20,
      ease: Power3.easeOut,
      delay: delay,
      stagger: {
        each: 0.05
      }
    });
  }

  static init(selector) {
    [...document.querySelectorAll(selector)].forEach(el => new this(el));
  }
}
