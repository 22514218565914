import Plyr from 'plyr';

export default class AudioFile {
  constructor(el) {
    this.elAudio = el;

    console.log(this.elAudio);

    new Plyr(this.elAudio, {
      controls: ['play', 'progress', 'mute']
    });
  }

  static init(selector) {
    [...document.querySelectorAll(selector)].forEach(el => new this(el));
  }
}
