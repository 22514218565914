import { CookieMessage, Navigation, Story, AudioFile } from './components';

class App {
  constructor() {
    window.addEventListener('load', () => {
      CookieMessage.init('.js-cookie-message');
      Navigation.init('.js-header');
      Story.init('.js-story');
      AudioFile.init('.js-audio-file')
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  new App();
});
