import Cookies from 'js-cookie';

export default class CookieMessage {
  constructor() {
    const container = document.querySelector('.js-cookie-message');
    if (!container) return;
    this.elements = {
      container,
      messageWrapper: container.querySelector('.js-cookie-message-wrapper'),
      optionsWrapper: container.querySelector('.js-cookie-options'),
      optionsCheckboxWrapper: container.querySelector('.js-cookie-options-checkbox-wrapper'),
      optionsDetailsWrapper: container.querySelector('.js-cookie-options-details-wrapper'),
      detailsContentBoxes: container.querySelectorAll('.js-cookie-details-content'),
      detailsHosts: container.querySelectorAll('.js-host'),
      optionsCheckboxes: container.querySelectorAll('.js-cookie-options input[type=checkbox]'),
      optionsCheckboxesInfo: container.querySelectorAll('.js-cookie-checkbox-info'),
      optionsCheckboxTitles: container.querySelectorAll('.js-cookie-checkbox-title'),
      buttonsCookieDetails: container.querySelectorAll('.js-cookie-checkbox-btn-details'),
      buttonCookieDetailsBack: container.querySelector('.js-cookie-details-back-btn'),
      buttonAgreeAll: container.querySelector('.js-cookie-agree-all'),
      buttonOptionsAgreeAll: container.querySelector('.js-cookie-options .js-cookie-agree-all'),
      buttonAgreeSelect: container.querySelector('.js-cookie-agree-select'),
      buttonDisagreeAll: container.querySelector('.js-cookie-disagree'),
      buttonChoose: container.querySelector('.js-cookie-choose'),
      buttonOptionsClose: container.querySelector('.js-cookie-options-close'),
      animationRectangle: container.querySelector('.js-cookie-animation_rect'),
    };

    this.elements.customCookieBtn = document.querySelector('.js-cookie-adjust-btn');

    this.cookieTypes = {};

    this.getCookies();
    this.addEvents();
  }

  addEvents() {
    // ------------------------------------------------------------------------------------------------ CONSENT BUTTONS
    this.elements.buttonAgreeAll.addEventListener('click', () => {
      this.agreeAll();
    });
    this.elements.buttonOptionsAgreeAll.addEventListener('click', () => {
      this.agreeAll();
    });
    this.elements.buttonDisagreeAll.addEventListener('click', () => {
      this.disagreeAll();
    });
    this.elements.buttonAgreeSelect.addEventListener('click', () => {
      this.agreeToSelection();
    });

    // ------------------------------------------------------------------------------------------------ OPTIONS BUTTONS
    this.elements.buttonChoose.addEventListener('click', () => {
      this.toggleCookieOptions();
    });
    this.elements.buttonOptionsClose.addEventListener('click', () => {
      this.elements.buttonChoose.click();
    });
    [...this.elements.optionsCheckboxes].forEach((checkbox) => {
      checkbox.addEventListener('change', () => {
        this.checkSelectedCheckboxes();
      });
      const checkBoxCookieType = checkbox.getAttribute('data-cookie-type');
      // Check if the checkbox was checked and store it in a variable
      this.cookieTypes[checkBoxCookieType] = checkbox.checked ? 1 : 0;
    });

    [...this.elements.optionsCheckboxTitles].forEach((checkBoxTitle) => {
      checkBoxTitle.addEventListener('click', () => {
        checkBoxTitle.parentElement.classList.toggle('js-visible');
      });
    });

    // ------------------------------------------------------------------------------------------------ DETAIL BUTTONS
    [...this.elements.buttonsCookieDetails].forEach((cookieDetailBtn) => {
      cookieDetailBtn.addEventListener('click', (ev) => {
        ev.preventDefault();
        this.showCookieDetails(cookieDetailBtn);
        this.toggleCookieDetailsWrapper();
      });
    });

    [...this.elements.detailsHosts].forEach((host) => {
      host.addEventListener('click', () => {
        host.classList.toggle('js-visible');
      });
    });

    this.elements.buttonCookieDetailsBack.addEventListener('click', () => {
      this.hideAllCookieDetails();
      this.toggleCookieDetailsWrapper();
    });

    // Custom cookie button
    if (this.elements.customCookieBtn) {
      this.elements.customCookieBtn.addEventListener('click', () => {
        this.openCookieNotice();
      });
    }
  }

  checkSelectedCheckboxes() {
    let customCookieWasChecked = true;
    [...this.elements.optionsCheckboxes].forEach((checkbox) => {
      const checkBoxCookieType = checkbox.getAttribute('data-cookie-type');
      // Check if the checkbox was checked and store it in a variable
      this.cookieTypes[checkBoxCookieType] = checkbox.checked ? 1 : 0;
      if (checkbox.checked && !checkbox.disabled) customCookieWasChecked = false;
    });
    if (customCookieWasChecked) { // 'mijn keuze bewaren'
      this.elements.buttonOptionsAgreeAll.classList.remove('hidden');
      this.elements.buttonAgreeSelect.classList.add('hidden');
    } else { // 'alle cookies aanvaarden'
      this.elements.buttonOptionsAgreeAll.classList.add('hidden');
      this.elements.buttonAgreeSelect.classList.remove('hidden');
    }
  }

  // ------------------------------------------------------------------------------------------------ SHOW/HIDE LAYERS
  closeCookieNotice() {
    this.elements.container.classList.remove('js-visible');
  }

  openCookieNotice() {
    this.elements.container.classList.add('js-visible');
  }

  // function to show the right cookie detail content
  showCookieDetails(cookieDetailBtn) {
    const cookieType = cookieDetailBtn.getAttribute('data-cookie-type');
    [...this.elements.detailsContentBoxes].forEach((contentBox) => {
      if (cookieType === contentBox.id) {
        contentBox.classList.add('js-visible');
      }
    });
  }

  hideAllCookieDetails() {
    [...this.elements.detailsContentBoxes].forEach((contentBox) => {
      contentBox.classList.remove('js-visible');
    });
  }

  toggleCookieDetailsWrapper() {
    this.elements.optionsCheckboxWrapper.classList.toggle('js-visible');
    this.elements.optionsDetailsWrapper.classList.toggle('js-visible');
  }

  toggleCookieOptions() {
    this.elements.messageWrapper.classList.toggle('js-visible');
    this.elements.optionsWrapper.classList.toggle('js-visible');
  }

  // ------------------------------------------------------------------------------------------------ CHOICES
  agreeAll() {
    // agree to all cookies
    for (const label in this.cookieTypes) {
      this.cookieTypes[label] = 1;
    }
    this.closeCookieNotice();
    this.setCookie();
  }

  agreeToSelection() {
    // agree to some cookies
    this.setCookie();
    this.closeCookieNotice();
  }

  disagreeAll() {
    // agree to all cookies
    for (const label in this.cookieTypes) {
      this.cookieTypes[label] = 0;
    }
    this.closeCookieNotice();
    this.setCookie();
  }


  // ------------------------------------------------------------------------------------------------ GET SET COOKIES
  getCookies() {
    const tmpCookie = Cookies.get('_cookie-consent');
    if (tmpCookie) {
      const cookieObj = JSON.parse(tmpCookie);
      this.cookieTypes = cookieObj;
    } else {
      this.openCookieNotice();
    }

    this.dataLayerEvent();
  }

  setCookie() {
    const expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 1);

    if (location.protocol !== 'https:') {
      Cookies.set('_cookie-consent', JSON.stringify(this.cookieTypes), {
        expires: expiryDate,
      });
    } else {
      Cookies.set('_cookie-consent', JSON.stringify(this.cookieTypes), {
        expires: expiryDate,
        secure: true,
      });
    }

    this.dataLayerEvent();
  }

  dataLayerEvent() {
    const dataLayerObj = {
      event: 'cookie_preferences'
    };
    for (const label in this.cookieTypes) {
      dataLayerObj[label] = this.cookieTypes[label];
    }

    window.dataLayer.push(dataLayerObj);
  }

  static init(selector) {
    [...document.querySelectorAll(selector)].forEach(el => new this(el));
  }
}
